import { IonPage } from "@ionic/react";
import { STREAK_TRACKER_PATH, StreakTrackerPage } from "@src/appV2/Streaks";
import { type ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { AccountRoutes, ACCOUNTS_PATH } from "../Accounts";
import { DEBUG_PATH, DebugRoutes } from "../Debug";
import { NotFoundPage } from "../lib";
import { PlacementCandidateModalRoutes } from "../redesign/PlacementCandidate/PlacementCandidateModalRoutes";
import { PLACEMENT_CANDIDATE_BASE_PATH, PLACEMENTS_BASE_PATH } from "../redesign/Placements/paths";
import { PlacementRoutes } from "../redesign/Placements/Routes";
import { ShiftDiscoveryContainer } from "../redesign/ShiftDiscovery/Container";
import { SHIFT_DISCOVERY_PATH, WORK_WITH_FRIENDS_PATH } from "../redesign/ShiftDiscovery/paths";
import { useIsWorkerShiftsRedesignEnabled } from "../redesign/WorkerShifts/api/useIsWorkerShiftsRedesignEnabled";
import { BreakPaymentRequestsPage } from "../redesign/WorkerShifts/BreakPaymentRequests/Page";
import { CreateSentHomeRequestPage } from "../redesign/WorkerShifts/CreateSentHomeRequest/Page";
import { WorkerShiftsPage } from "../redesign/WorkerShifts/Page";
import {
  BREAK_PAYMENT_REQUESTS_PATH,
  CREATE_SENT_HOME_REQUEST_PATH,
  SENT_HOME_REQUESTS_PATH,
  SHIFT_DETAILS_PATH,
  SHIFT_INVITES_PATH,
} from "../redesign/WorkerShifts/path";
import { SentHomeRequestsPage } from "../redesign/WorkerShifts/SentHomeRequests/Page";
import { ShiftDetailsPage } from "../redesign/WorkerShifts/ShiftDetails/Page";
import { ShiftInvitesPage as RedesignedShiftInvitesPage } from "../redesign/WorkerShifts/ShiftInvites/Page";
import { UnverifiedShiftsPage as RedesignedUnverifiedShiftsPage } from "../redesign/WorkerShifts/UnverifiedShifts/Page";
import { WorkWithFriendsContainer } from "../redesign/WorkWithFriends/Container";
import { SHIFT_BLOCKS_PATH } from "../ShiftBlocks/paths";
import { ShiftBlockRoutes } from "../ShiftBlocks/Routes";
import { MY_SHIFTS_PATH, MyShiftsPage } from "../Shifts/MyShifts";
import { ShiftInvitesPage } from "../Shifts/ShiftInvites/Page";
import { UNVERIFIED_SHIFTS_PATH } from "../Shifts/UnverifiedShifts/constants";
import { UnverifiedShiftsPage } from "../Shifts/UnverifiedShifts/Page";
import { WORKER_AVAILABILITIES_PATH, WorkerAvailabilityPage } from "../WorkerAvailability";
// eslint-disable-next-line import/max-dependencies
import { WorkerAvailabilityProvider } from "../WorkerAvailability/context";

export function AppV2PrivateRoutes(): ReactElement {
  const match = useRouteMatch();
  const isWorkerShiftsRedesignEnabled = useIsWorkerShiftsRedesignEnabled();

  return (
    <Switch>
      <Route path={`${match.url}/${DEBUG_PATH}`}>
        <DebugRoutes />
      </Route>
      <Route path={`${match.url}/${ACCOUNTS_PATH}`}>
        <AccountRoutes />
      </Route>
      <Route exact path={`${match.url}/${MY_SHIFTS_PATH}`}>
        {isWorkerShiftsRedesignEnabled ? (
          <WorkerShiftsPage />
        ) : (
          <IonPage>
            <MyShiftsPage />
          </IonPage>
        )}
      </Route>
      <Route exact path={`${match.url}/${UNVERIFIED_SHIFTS_PATH}`}>
        {isWorkerShiftsRedesignEnabled ? (
          <RedesignedUnverifiedShiftsPage />
        ) : (
          <IonPage>
            <UnverifiedShiftsPage />
          </IonPage>
        )}
      </Route>
      <Route exact path={`${match.url}/${BREAK_PAYMENT_REQUESTS_PATH}`}>
        <BreakPaymentRequestsPage />
      </Route>
      <Route exact path={`${match.url}/${SENT_HOME_REQUESTS_PATH}`}>
        <SentHomeRequestsPage />
      </Route>
      <Route exact path={`${match.url}/${CREATE_SENT_HOME_REQUEST_PATH}`}>
        <CreateSentHomeRequestPage />
      </Route>
      <Route path={`${match.url}/${SHIFT_DETAILS_PATH}`}>
        <ShiftDetailsPage />
      </Route>
      <Route exact path={`${match.url}/${SHIFT_INVITES_PATH}`}>
        {isWorkerShiftsRedesignEnabled ? (
          <RedesignedShiftInvitesPage />
        ) : (
          <IonPage>
            <ShiftInvitesPage />
          </IonPage>
        )}
      </Route>
      <Route exact path={`${match.url}/${WORKER_AVAILABILITIES_PATH}`}>
        <IonPage>
          <WorkerAvailabilityProvider>
            <WorkerAvailabilityPage />
          </WorkerAvailabilityProvider>
        </IonPage>
      </Route>
      <Route path={`${match.url}/${SHIFT_BLOCKS_PATH}`}>
        <ShiftBlockRoutes />
      </Route>
      <Route exact path={STREAK_TRACKER_PATH}>
        <StreakTrackerPage />
      </Route>
      <Route exact path={match.url}>
        <Redirect to={`${match.url}/${DEBUG_PATH}`} />
      </Route>
      <Route path={`${match.url}/${SHIFT_DISCOVERY_PATH}`}>
        <ShiftDiscoveryContainer />
      </Route>
      <Route path={`${match.url}/${WORK_WITH_FRIENDS_PATH}`}>
        <WorkWithFriendsContainer />
      </Route>
      <Route path={`${match.url}/${PLACEMENTS_BASE_PATH}`}>
        <PlacementRoutes />
      </Route>
      <Route path={`${match.url}/${PLACEMENT_CANDIDATE_BASE_PATH}`}>
        <PlacementCandidateModalRoutes />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
